const en = {
  cardButtons: {
    accessCode: 'Access Code',
    edit: 'Edit',
    manage: 'Manage',
    unpublish: 'Unpublish',
  },
  components: {
    timezoneInput: {
      timezone: 'Timezone',
    },
  },
  dCloudLogo: {
    topLine: 'Labs brought to you by',
    bottomLine: 'Cisco dCloud',
  },
  publishExpo: {
    cancel: 'Cancel',
    publish: 'Publish',
    title: 'Publish',
    content: {
      note: 'Please note that documentation for demos associated to an eXpo are directly accessible via the public Internet whilst the eXpo is running. If your eXpo contains restricted content such as non-FCS code, please contact the dCloud Support Team before publishing the eXpo.',
      question: 'Are you sure you want to publish this eXpo?',
    },
  },
};

export default en;
