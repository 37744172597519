import { connect } from 'react-redux';
import {
  getDemos,
  getScheduledDemos,
  getFilterBy,
  getRebalanceDCFilters,
  getInputTargetState,
  getRebalanceContentPoolFilters,
} from '../../../../../redux/admin/info/selectors';
import { infoActions } from '../../../../../redux/admin/info/actions';
import Rebalance from '.';
import { IApplicationState } from '../../../../../redux/types';

const mapStateToProps = (state: IApplicationState) => ({
  demos: getDemos(state),
  filterBy: getFilterBy(state),
  scheduledDemos: getScheduledDemos(state),
  dcFilters: getRebalanceDCFilters(state),
  contentPoolFiltersMap: getRebalanceContentPoolFilters(state),
  inputTargetState: getInputTargetState(state),
});

const mapDispatchToProps = {
  setFilter: infoActions.setFilter,
  setTargetState: infoActions.setTargetState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Rebalance);
