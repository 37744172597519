import { createAction } from '../../../action-helpers';
import { ActionsUnion } from '../../../action-types';
import { IAccessCode, ISavedExpo } from '../../../../app/entities/expos/types';
import {
  ExpoStates,
  ExpoDateFilters,
} from '../../../../app/entities/expos/constants';

export const DELETE_EXPO = 'ADMIN/EXPOS/DELETE';
export const DELETE_EXPO_SUCCESS = 'ADMIN/EXPOS/DELETE_SUCCESS';
export const FETCH_ACCESS_CODE = 'ADMIN/EXPOS/FETCH_ACCESS_CODE';
export const FETCH_ACCESS_CODE_SUCCESS = 'ADMIN/EXPOS/FETCH_ACCESS_CODE_ACCESS';
export const FETCH_EXPOS = 'ADMIN/EXPOS/FETCH';
export const FETCH_EXPOS_SUCCESS = 'ADMIN/EXPOS/FETCH_SUCCESS';
export const PUBLISH_EXPO = 'ADMIN/EXPOS/PUBLISH_EXPO';
export const PUBLISH_EXPO_SUCCESS = 'ADMIN/EXPOS/PUBLISH_EXPO_SUCCESS';
export const RESET = 'ADMIN/EXPOS/RESET';
export const SET_FILTER = 'ADMIN/EXPOS/SET_FILTER';
export const SET_SORT = 'ADMIN/EXPOS/SET_SORT';
export const UNPUBLISH_EXPO = 'ADMIN/EXPOS/UNPUBLISH_EXPO';
export const UNPUBLISH_EXPO_SUCCESS = 'ADMIN/EXPOS/UNPUBLISH_EXPO_SUCCESS';

const deleteExpo = (uid: string) => createAction(DELETE_EXPO, uid);
const deleteExpoSuccess = (uid: string) =>
  createAction(DELETE_EXPO_SUCCESS, uid);
const fetchAccessCode = (uid: string) => createAction(FETCH_ACCESS_CODE, uid);
const fetchAccessCodeSuccess = (accessCode: IAccessCode) =>
  createAction(FETCH_ACCESS_CODE_SUCCESS, accessCode);
const fetchExpos = () => createAction(FETCH_EXPOS);
const fetchExposSuccess = (expos: ISavedExpo[]) =>
  createAction(FETCH_EXPOS_SUCCESS, expos);
const publishExpo = (uid: string) => createAction(PUBLISH_EXPO, uid);
const publishExpoSuccess = (uid: string) =>
  createAction(PUBLISH_EXPO_SUCCESS, uid);
const reset = () => createAction(RESET);
const setFilter = (filter: ExpoStates) => createAction(SET_FILTER, filter);
const setSort = (sortBy: ExpoDateFilters) => createAction(SET_SORT, sortBy);
const unpublishExpo = (uid: string) => createAction(UNPUBLISH_EXPO, uid);
const unpublishExpoSuccess = (uid: string) =>
  createAction(UNPUBLISH_EXPO_SUCCESS, uid);

export const exposActions = {
  deleteExpo,
  deleteExpoSuccess,
  fetchAccessCode,
  fetchAccessCodeSuccess,
  fetchExpos,
  fetchExposSuccess,
  publishExpo,
  publishExpoSuccess,
  reset,
  setFilter,
  setSort,
  unpublishExpo,
  unpublishExpoSuccess,
};

export type Actions = ActionsUnion<typeof exposActions>;
