import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { getSingleExpo } from '../../../../../app/entities/expos/service';
import { IActionWithPayload } from '../../../../action-helpers';
import { editActions, FETCH_EXPO } from '../../actions';
import { IExpoResponse } from '../../../../../app/entities/expos/types';
import { removeGzip } from '../../../../../app/common/utilities/headers';
import { globalUiActions } from '../../../../ui/loader/actions';
import { stringifySeats } from '../../../../../app/admin/edit/utilities';
import { mapFromExpoResponse } from '../../../../../app/entities/expos/utilities';

type Action = IActionWithPayload<typeof FETCH_EXPO, string>;

interface IReceivedHeaders {
  etag: string;
}

interface IReturnedPayload {
  data: IExpoResponse;
  headers: IReceivedHeaders;
}

export default function* fetchExpoWorker(action: Action): SagaIterator {
  yield put(globalUiActions.showLoader());

  try {
    const { data, headers }: IReturnedPayload = yield call(
      getSingleExpo,
      action.payload,
    );

    const savedExpo = mapFromExpoResponse(data);
    const payload = {
      expo: stringifySeats(savedExpo),
      etag: removeGzip(headers.etag),
    };

    yield put(editActions.fetchExpoSuccess(payload));
  } catch (e) {
    throw e;
  } finally {
    yield put(globalUiActions.hideLoader());
  }
}
