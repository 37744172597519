import { call, put } from 'redux-saga/effects';
import { IActionWithPayload } from '../../../../action-helpers';
import { infoActions, GET_EXPO_DEMOS } from '../../actions';
import { getSingleExpoDemos } from '../../../../../app/entities/expos/service';
import { SagaIterator } from 'redux-saga';

type Action = IActionWithPayload<typeof GET_EXPO_DEMOS, string>;

export default function* getExpoDemosWorker(action: Action): SagaIterator {
  yield put(infoActions.showLoader());

  try {
    const response: IDemoDetails[] = yield call(
      getSingleExpoDemos,
      action.payload,
    );

    yield put(infoActions.getExpoDemosSuccess(response));
  } catch (e) {
    throw e;
  } finally {
    yield put(infoActions.hideLoader());
  }
}
