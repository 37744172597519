import { withModal } from '../../../pov-common-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IApplicationState } from '../../../../../redux/types';
import { MODAL_NAMES } from '../constants';
import { modalActions } from '../../../../../redux/modals/actions';
import { toastActions } from '../../../../../redux/toast/actions';
import AccessCodeModal from '../access-code';
import AnyConnectModal from '../any-connect';
import DeleteExpoModal from '../delete-expo/container';
import DemoResourcesModal from '../demo-resources';
import EndDemoEngagementModal from '../end-demo-engagement/container';
import ExploreDemoModal from '../explore-demo';
import ExpoChangedModal from '../expo-changed';
import GenericErrorModal from '../generic-error';
import GetStartedModal from '../get-started';
import PublishExpoModal from '../publish-expo/container';
import UnpublishExpoModal from '../unpublish-expo/container';
import { IModalProps } from '../modal-types';
import {
  getModalProps,
  getOpenModalName,
  getModalLoading,
} from '../../../../../redux/modals/selectors';

export interface IProps {
  closeModal?: () => void;
  showToast?: () => void;
  modalName?: string;
  modalProps?: IModalProps | null;
}

export const MODAL_CONTENT = {
  [MODAL_NAMES.ACCESS_CODE]: AccessCodeModal,
  [MODAL_NAMES.ANY_CONNECT]: AnyConnectModal,
  [MODAL_NAMES.DELETE_EXPO]: DeleteExpoModal,
  [MODAL_NAMES.DEMO_RESOURCES]: DemoResourcesModal,
  [MODAL_NAMES.END_ENGAGEMENT]: EndDemoEngagementModal,
  [MODAL_NAMES.EXPLORE_DEMO]: ExploreDemoModal,
  [MODAL_NAMES.EXPO_CHANGED]: ExpoChangedModal,
  [MODAL_NAMES.GENERIC_ERROR]: GenericErrorModal,
  [MODAL_NAMES.GETTING_STARTED]: GetStartedModal,
  [MODAL_NAMES.PUBLISH_EXPO]: PublishExpoModal,
  [MODAL_NAMES.UNPUBLISH_EXPO]: UnpublishExpoModal,
};

export function Modal({
  closeModal,
  showToast,
  modalName = '',
  modalProps,
}: IProps) {
  const spreadProps = { closeModal, showToast };

  const Component: React.ComponentClass = MODAL_CONTENT[modalName];

  return <Component {...spreadProps} {...modalProps} />;
}

const mapStateToProps = (state: IApplicationState) => {
  const name = getOpenModalName(state);

  return {
    isOpen: !!name,
    modalName: name,
    modalProps: getModalProps(state),
    loading: getModalLoading(state),
  };
};

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  showToast: toastActions.showToast,
};

export default compose<typeof Modal>(
  connect(mapStateToProps, mapDispatchToProps),
  withModal,
)(Modal);
