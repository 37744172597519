import * as React from 'react';
import { Card, CardHeader, CardContent } from '../../common/pov-common-ui';
import { Route, Switch } from 'react-router';
import { ROUTES } from '../../common/constants';
import Layout from '../../common/components/layout';
import { Header } from '../components/header';
import { modalActions } from '../../../redux/modals/actions';
import Content from './components/content/container';
import Rebalance from './components/rebalance/container';
import Engagements from './components/engagements/container';
import { ISavedExpo } from '../../entities/expos/types';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { infoActions } from '../../../redux/admin/info/actions';
import './styles.scss';
import { MAIN_BACKGROUND_IMAGE } from '../../common/utilities/imageAssets';
import { ReactElement } from 'react';

export interface IProps extends RouteComponentProps<{ id: string }> {
  expo?: ISavedExpo;
  openModal: typeof modalActions.openModal;
  fetchDemos: typeof infoActions.getExpoDemos;
}

export default function Info({
  expo,
  match,
  fetchDemos,
}: IProps): ReactElement {
  const expoUid = match.params.id;

  React.useEffect(() => {
    fetchDemos(expoUid);
  }, [expoUid, fetchDemos]);

  return (
    <>
      <Header showSave={false} />
      <Layout url={expo!.backgroundImage || MAIN_BACKGROUND_IMAGE}>
        <Card>
          <CardHeader title={expo!.name} alignTitle="center" />
          <CardContent>
            <ul role="tablist" className="tabs info-tabs">
              <li>
                <NavLink
                  exact={true}
                  to={ROUTES.ADMIN_VIEW_SESSION_INFO_CONTENT.replace(
                    ':id',
                    expoUid,
                  )}
                  activeClassName="active"
                >
                  Content
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact={true}
                  to={ROUTES.ADMIN_VIEW_SESSION_INFO_ENGAGEMENTS.replace(
                    ':id',
                    expoUid,
                  )}
                  activeClassName="active"
                >
                  Users
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact={true}
                  to={ROUTES.ADMIN_VIEW_SESSION_INFO_REBALANCE.replace(
                    ':id',
                    expoUid,
                  )}
                  activeClassName="active"
                >
                  Rebalance
                </NavLink>
              </li>
            </ul>

            <Switch>
              <Route
                exact={true}
                path={ROUTES.ADMIN_VIEW_SESSION_INFO_CONTENT.replace(
                  ':id',
                  expoUid,
                )}
                render={() => <Content />}
              />
              <Route
                exact={true}
                path={ROUTES.ADMIN_VIEW_SESSION_INFO_ENGAGEMENTS.replace(
                  ':id',
                  expoUid,
                )}
                component={() => <Engagements expoUid={expoUid} />}
              />
              <Route
                exact={true}
                path={ROUTES.ADMIN_VIEW_SESSION_INFO_REBALANCE.replace(
                  ':id',
                  expoUid,
                )}
                component={() => <Rebalance expoUid={expoUid} />}
              />
            </Switch>
          </CardContent>
        </Card>
      </Layout>
    </>
  );
}
