import * as React from 'react';
import Layout from '../../common/components/layout';
import NavBar from '../../common/components/nav-bar';
import Topbar from '../../common/components/top-bar';
import Expos from './expos';
import ExposLoading from './expos-loading';
import Filters from './filters';
import { exposActions } from '../../../redux/admin/expos/actions';
import { ExpoStates, ExpoDateFilters } from '../../entities/expos/constants';
import { ISavedExpo } from '../../entities/expos/types';
import './styles.scss';
import { imageAsset } from '../../common/utilities/imageAssets';
import { ReactElement } from 'react';

export interface IProps {
  expos: ISavedExpo[];
  filterBy: ExpoStates;
  fetchExpos: typeof exposActions.fetchExpos;
  loading: boolean;
  setSortBy: typeof exposActions.setSort;
  setFilterBy: typeof exposActions.setFilter;
  sortBy: ExpoDateFilters;
  onAccessCodeClick: (uid: string) => void;
  onDeleteIconClick: (uid: string) => void;
  onUnpublishButtonClick: (uid: string) => void;
  reset: () => void;
}

function Menu({
  expos,
  fetchExpos,
  filterBy,
  loading,
  sortBy,
  setSortBy,
  setFilterBy,
  onAccessCodeClick,
  onDeleteIconClick,
  onUnpublishButtonClick,
  reset,
}: IProps): ReactElement {
  React.useEffect(() => {
    fetchExpos();
  }, [filterBy, sortBy, fetchExpos]);

  React.useEffect(() => {
    return () => reset();
  }, [reset]);

  return (
    <div className="menu-page">
      <NavBar />
      <Layout url={imageAsset('admin/background.png')} hideTopBar={true}>
        <Topbar
          right={
            !!(expos.length || filterBy !== ExpoStates.ALL) && (
              <Filters
                filterBy={filterBy}
                sortBy={sortBy}
                setSortBy={setSortBy}
                setFilterBy={setFilterBy}
              />
            )
          }
        />
        {loading ? (
          <ExposLoading />
        ) : (
          <Expos
            expos={expos}
            filterBy={filterBy}
            onDeleteIconClick={onDeleteIconClick}
            onAccessCodeClick={onAccessCodeClick}
            onUnpublishButtonClick={onUnpublishButtonClick}
          />
        )}
      </Layout>
    </div>
  );
}

export default Menu;
