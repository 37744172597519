import { EngagementTimeoutValues, ExpoEventTypes } from './constants';
import {
  IExpo,
  IExpoBase,
  IExpoCreateRequest,
  IExpoEditRequest,
  IExpoResponse,
  IPublicExpoResponse,
  ISavedExpo,
  ISurveyType,
} from './types';
import { expoEvents } from './defaults';

const BASE_OPTIONS: IOption[] = [{ name: 'Please select', value: '' }];

export const CUSTOM_IMAGE_VALUE = 'custom';

const CUSTOM_IMAGE_OPTION: IOption[] = [
  { name: 'Custom Image', value: CUSTOM_IMAGE_VALUE },
];

const {
  EXTENSION_MAX_MINS,
  EXTENSION_MIN_MINS,
  WARNING_MAX_MINS,
  WARNING_MIN_MINS,
} = EngagementTimeoutValues;

export const getRange = (start: number, stop: number, step: number): number[] =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const extensionHrsOptions = (): IOption[] => {
  const range: number[] = getRange(EXTENSION_MIN_MINS, EXTENSION_MAX_MINS, 60);

  return range.map((value) => ({ name: (value / 60).toString(), value }));
};

export const extensionMinsOptions = (): IOption[] => {
  const range: number[] = getRange(0, 45, 15);

  return range.map((value) => ({ name: value.toString(), value }));
};

export const timeoutWarningOptions = (): IOption[] => {
  const range: number[] = getRange(WARNING_MIN_MINS, WARNING_MAX_MINS, 15);

  return range.map((value) => ({ name: value.toString(), value }));
};

export const expoTypeOptions = (): IOption[] => {
  const types: IOption[] = expoEvents.map((type) => ({
    name: type.name,
    value: type.value,
  }));

  return [...BASE_OPTIONS, ...types];
};

export const backgroundImagesOptions = (
  typeValue: ExpoEventTypes,
): IOption[] => {
  if (!typeValue) return BASE_OPTIONS;

  const chosenType = expoEvents.find((type) => type.value === typeValue);

  const images: IOption[] = chosenType!.images.map((image) => ({
    name: image.name,
    value: image.value,
    thumb: image.value,
  }));

  return [...BASE_OPTIONS, ...CUSTOM_IMAGE_OPTION, ...images];
};

export const feedbackSurveyOptions = (
  surveyTypes: ISurveyType[],
): IOption[] => {
  const options = surveyTypes.map((item) => ({
    name: item.name,
    value: item.id,
  }));
  return [...BASE_OPTIONS, ...options];
};

const mapToExpoRequest = (
  expo: IExpoBase | ISavedExpo,
): IExpoCreateRequest | IExpoEditRequest => {
  const expoRequest = JSON.parse(JSON.stringify(expo));

  delete expoRequest.feedbackSurveyManagement;
  delete expoRequest.feedbackOption;
  delete expoRequest.feedbackUrl;
  delete expoRequest.feedbackName;

  if (expo.feedbackSurveyManagement) {
    expoRequest.survey = {
      type: {
        id: expo.feedbackOption,
      },
      url: expo.feedbackUrl,
    };
  }

  return expoRequest;
};

export const mapToExpoCreateRequest = (expo: IExpoBase): IExpoCreateRequest =>
  mapToExpoRequest(expo);

export const mapToExpoEditRequest = (expo: ISavedExpo): IExpoEditRequest =>
  mapToExpoRequest(expo) as IExpoEditRequest;

export const mapFromExpoResponse = (
  expo: IExpoResponse | IPublicExpoResponse,
): ISavedExpo | IExpo => {
  const savedExpo = JSON.parse(JSON.stringify(expo));

  delete savedExpo.survey;
  savedExpo.feedbackSurveyManagement = expo.survey != null;
  savedExpo.feedbackOption = expo.survey?.type.id;
  savedExpo.feedbackName = expo.survey?.type.name;
  savedExpo.feedbackUrl = expo.survey?.url;

  return savedExpo;
};

export const mapFromPublicExpoResponse = (expo: IPublicExpoResponse): IExpo =>
  mapFromExpoResponse(expo) as IExpo;

export const isExpoTypeInvalid = (type: string): boolean => {
  return !expoEvents.some((event) => event.value === type);
};
