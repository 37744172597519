import * as React from 'react';
import { targetColumns } from './columns';
import { infoActions } from '../../../../../redux/admin/info/actions';
import { DataGrid, Dropdown, Label } from '../../../../common/pov-common-ui';
import RebalanceControls from '../rebalance-controls/container';

interface IFilter {
  name: string;
  value: string;
}

interface IProps {
  demos: IDemoDetails[];
  scheduledDemos: IDemoDetails[];
  filterBy: IRebalanceFilterValues;
  setFilter: typeof infoActions.setFilter;
  setTargetState: typeof infoActions.setTargetState;
  dcFilters: IFilter[];
  contentPoolFiltersMap: Map<string, IFilter[]>;
  inputTargetState: ITargetState;
  expoUid: string;
}

export default function Rebalance({
  demos,
  scheduledDemos,
  filterBy,
  setFilter,
  dcFilters,
  contentPoolFiltersMap,
  inputTargetState,
  setTargetState,
  expoUid,
}: IProps) {
  const data = React.useMemo(
    () =>
      scheduledDemos.filter(
        (demo: {
          dataCenter: string;
          contentPool: IContentPool;
          type: string;
        }) =>
          demo.dataCenter === filterBy.dataCenter &&
          demo.contentPool.id === filterBy.contentPool.id,
      ),
    [filterBy, scheduledDemos],
  );

  const contentPoolFilters = React.useMemo(
    () => contentPoolFiltersMap.get(filterBy.dataCenter) ?? [],
    [filterBy.dataCenter, contentPoolFiltersMap],
  );

  const onDcDropdownChange = React.useCallback(
    (dropdownName: string, dataCenterName: string) => {
      const [defaultContentPool] =
        contentPoolFiltersMap.get(dataCenterName) ?? [];
      const { value: id = '', name = '' } = defaultContentPool;
      setFilter({
        dataCenter: dataCenterName,
        contentPool: {
          id,
          name,
        },
      });
    },
    [setFilter, contentPoolFiltersMap],
  );

  const onContentPoolDropdownChange = React.useCallback(
    (dropdownName: string, contentPoolId: string) => {
      const contentPoolName =
        contentPoolFilters.find(
          (contentPool) => contentPoolId === contentPool.value,
        )?.name ?? '';
      setFilter({
        ...filterBy,
        contentPool: {
          id: contentPoolId,
          name: contentPoolName,
        },
      });
    },
    [filterBy, setFilter, contentPoolFilters],
  );

  if (scheduledDemos.length === 0) return null;
  return (
    <div className="rebalance-page-wrapper">
      {demos.length && (
        <div className="rebalance-filter-controls">
          <Label labelFor="admin_dc_filter_to_rebalance" required={false}>
            Data Center
          </Label>
          <Dropdown
            id="admin_dc_filter_to_rebalance"
            name="admin_dc_filter_to_rebalance"
            onChange={onDcDropdownChange}
            selected={filterBy.dataCenter}
            options={dcFilters}
          />
          <Label labelFor="admin_cp_filter_to_rebalance" required={false}>
            Content Pool
          </Label>
          <Dropdown
            id="admin_cp_filter_to_rebalance"
            name="admin_cp_filter_to_rebalance"
            onChange={onContentPoolDropdownChange}
            selected={filterBy.contentPool.id}
            options={contentPoolFilters}
          />
        </div>
      )}
      <RebalanceControls expoUid={expoUid} />
      <DataGrid
        columns={targetColumns(setTargetState, inputTargetState)}
        data={data}
        sortable={true}
        minRows={1}
        pageSize={500}
      />
    </div>
  );
}
