import * as React from 'react';
import { Columns } from '../../../../common/pov-common-ui';
import Cell from '../../../../common/components/table-cell';

const formatColumnData = (data: string | number): string =>
  data === undefined ? '-' : `${data}`;

export const contentColumns: Columns<IDemoDetails> = [
  {
    Header: 'Demo ID',
    Cell: (cellInfo) => (
      <Cell value={formatColumnData(cellInfo.original.dCloudId)} />
    ),
    accessor: 'dCloudId',
    minWidth: 30,
  },
  {
    Header: 'DC',
    Cell: (cellInfo) => (
      <Cell value={formatColumnData(cellInfo.original.dataCenter)} />
    ),
    accessor: 'dataCenter',
    minWidth: 20,
  },
  {
    Header: 'Name',
    Cell: (cellInfo) => (
      <Cell value={formatColumnData(cellInfo.original.name)} />
    ),
    accessor: 'name',
    minWidth: 57,
  },
  {
    Header: 'Type',
    Cell: (cellInfo) => (
      <Cell value={formatColumnData(cellInfo.original.type)} />
    ),
    accessor: 'type',
    minWidth: 30,
  },
  {
    Header: 'Target',
    Cell: (cellInfo) => (
      <Cell value={formatColumnData(cellInfo.original.targetSessions)} />
    ),
    accessor: 'targetSessions',
    minWidth: 20,
  },
  {
    Header: 'Running',
    Cell: (cellInfo) => (
      <Cell value={formatColumnData(cellInfo.original.runningSessions)} />
    ),
    accessor: 'runningSessions',
    minWidth: 20,
  },
  {
    Header: 'In Use',
    Cell: (cellInfo) => (
      <Cell value={formatColumnData(cellInfo.original.activeEngagements)} />
    ),
    accessor: 'activeEngagements',
    minWidth: 20,
  },
  {
    Header: 'Scheduled',
    Cell: (cellInfo) => (
      <Cell value={formatColumnData(cellInfo.original.scheduledSessions)} />
    ),
    accessor: 'scheduledSessions',
    minWidth: 24,
  },
  {
    Header: 'Starting',
    Cell: (cellInfo) => (
      <Cell value={formatColumnData(cellInfo.original.startingSessions)} />
    ),
    accessor: 'startingSessions',
    minWidth: 20,
  },
  {
    Header: 'Stopping',
    Cell: (cellInfo) => (
      <Cell value={formatColumnData(cellInfo.original.stoppingSessions)} />
    ),
    accessor: 'stoppingSessions',
    minWidth: 20,
  },
  {
    Header: 'Stopped',
    Cell: (cellInfo) => (
      <Cell value={formatColumnData(cellInfo.original.stoppedSessions)} />
    ),
    accessor: 'stoppedSessions',
    minWidth: 20,
  },
  {
    Header: 'Error',
    Cell: (cellInfo) => (
      <Cell value={formatColumnData(cellInfo.original.errorSessions)} />
    ),
    accessor: 'errorSessions',
    minWidth: 20,
  },
];
