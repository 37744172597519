import {
  Actions,
  GET_EXPO_DEMOS_SUCCESS,
  SHOW_LOADER,
  HIDE_LOADER,
  GET_EXPO_ENGAGEMENTS_SUCCESS,
  SET_FILTER,
  SET_TARGET_STATE,
  UPDATE_TARGET_COUNT_SUCCESS,
  END_ENGAGEMENT_SUCCESS,
} from '../actions';

export type IExpoInfoState = Readonly<typeof initialState>;

const filterScheduledDemos = (demos: IDemoDetails[]) =>
  demos.filter((demo) => demo.type !== 'INSTANT');

export const normalizeSessionCounts = (
  dataCenter: string,
  contentPoolId: string,
  demos: IDemoDetails[],
) =>
  Object.assign(
    {},
    ...demos
      .filter(
        (demo: {
          dataCenter: string;
          contentPool: IContentPool;
          type: string;
        }) =>
          demo.dataCenter === dataCenter &&
          demo.contentPool.id === contentPoolId,
      )
      .map((demo: IDemoDetails) => ({ [demo.uid]: demo.targetSessions })),
  );

export const calculateTotal = (targetState: ITargetState) => {
  const targetStateKeys = Object.keys(targetState);

  return targetStateKeys.length
    ? targetStateKeys
        .map((val) => targetState[val] || 0)
        .reduce((a: number, b: number) => a + b)
    : 0;
};

export const initialState = {
  loading: false,
  demos: [] as IDemoDetails[],
  engagements: [] as IEngagementDetails[],
  targetInputIsDirty: false,
  initialTotalTargetSessions: 0,
  totalTargetSessions: 0,
  inputTargetState: {},
  filterBy: {} as IRebalanceFilterValues,
};

export default function expoInfoReducer(
  state: IExpoInfoState = initialState,
  action: Actions,
): IExpoInfoState {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        loading: true,
      };

    case HIDE_LOADER:
      return {
        ...state,
        loading: false,
      };

    case GET_EXPO_DEMOS_SUCCESS:
      const { dataCenter = '', contentPool = { id: '', name: '' } } = {
        ...action.payload.find((demo) => demo.type !== 'INSTANT'),
      };

      const defaultTargetState = normalizeSessionCounts(
        dataCenter,
        contentPool.id,
        filterScheduledDemos(action.payload),
      );

      const total = calculateTotal(defaultTargetState);

      return {
        ...state,
        targetInputIsDirty: false,
        inputTargetState: defaultTargetState,
        totalTargetSessions: total,
        initialTotalTargetSessions: total,
        demos: action.payload,
        filterBy: {
          dataCenter,
          contentPool,
        },
      };

    case GET_EXPO_ENGAGEMENTS_SUCCESS:
      return {
        ...state,
        engagements: action.payload,
      };

    case END_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        engagements: state.engagements.filter(
          (item) => action.payload !== item.uid,
        ),
      };

    case SET_FILTER: {
      const targetState = normalizeSessionCounts(
        action.payload.dataCenter,
        action.payload.contentPool.id,
        filterScheduledDemos(state.demos),
      );
      return {
        ...state,
        filterBy: action.payload,
        targetInputIsDirty: false,
        inputTargetState: targetState,
        totalTargetSessions: calculateTotal(targetState),
        initialTotalTargetSessions: calculateTotal(targetState),
      };
    }

    case SET_TARGET_STATE: {
      return {
        ...state,
        inputTargetState: action.payload,
        totalTargetSessions: calculateTotal(action.payload),
        targetInputIsDirty: true,
      };
    }

    case UPDATE_TARGET_COUNT_SUCCESS: {
      return {
        ...state,
        targetInputIsDirty: false,
      };
    }

    default:
      return state;
  }
}
